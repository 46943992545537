/* eslint-disable camelcase */
import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from "@/utils/toast";
import specialistStoreModule from '../specialistStoreModule';

export default function useSpecialistModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'specialist';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, specialistStoreModule);
  }

  // Use toast
  const toastification = toast();
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const {
      name, image, status, parent,
    } = itemLocal.value;

    const data = {
      name, image, status,
    };

    if (parent) {
      data.parent = parent._id;
    }

    console.log(itemLocal.value._id);

    if (itemLocal.value._id) {
      delete data.password;
      data._id = itemLocal.value._id;
      store
        .dispatch('specialist/updateSpecialist', data)
        .then(() => { })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
        });
    } else {
      store
        .dispatch('specialist/createSpecialist', data)
        .then(response => { emit('on-item-created', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
        });
    }
  };

  return {
    refModal,
    refForm,
    itemLocal,
    resetItemLocal,
    resetModal,
    onSubmit,
  };
}
